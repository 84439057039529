import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const CustomField = ({ id, type, value, label, touched, errors }) => {
  let field = "";
  let labelContent = "";
  if (label) {
    labelContent = <label htmlFor={id}>{label}</label>;
  }
  let errorContent = "";
  if (touched && errors) {
    errorContent = (
      <span style={{ marginTop: "5px", color: "red", fontSize: "12px" }}>
        {errors}
      </span>
    );
  }
  switch (type) {
    case "textarea":
      field = <Field name={id} id={id} as="textarea" />;
      break;
    default:
      field = <Field name={id} type={type} id={id} value={value} />;
      break;
  }
  return (
    <div className="form-item">
      {labelContent}
      {field}
      {errorContent}
    </div>
  );
};

const ContactForm = ({}) => {
  return (
    <div className="contact-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Formik
              initialValues={{
                name: "",
                email: "",
                phoneNumber: "",
                message: "",
              }}
              validateOnChange
              validationSchema={Yup.object({
                names: Yup.string()
                  .max(15, "must be 15 character or less")
                  .required("Required"),
                email: Yup.string().email("invalid email").required("required"),
                phoneNumber: Yup.string()
                  .max(10, "mobile number should be 10 digit")
                  .matches(phoneRegExp, "Phone number is not valid")
                  .required("required"),
                message: Yup.string().ensure().required("required"),
              })}
              onSubmit={(values, actions) => {}}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="contact-form">
                        <CustomField id="names" label="Name*" />
                        <CustomField id="phoneNumber" label="Phone Number*" />
                        <CustomField id="email" label="Email*" />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7">
                      <div className="contact-form textarea-text">
                        <CustomField
                          type="textarea"
                          id="message"
                          label="Message*"
                        />
                        <div className="form-btn">
                          <button className="common-btn" type="submit">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
