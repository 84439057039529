import React from "react";
import ContactForm from "../components/ContactForm";
import Layout from "../components/Layout";
import StoreInformation from "../components/StoreInformation";
import { StaticImage } from "gatsby-plugin-image";

const Contact = () => {
  return (
    <Layout title="Tasty Pizza – Contact">
      <div className="contact-top-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="contact-text">
                <h3>
                  Call or Visit Tasty Pizza on Main Street in Dartmouth, NS
                </h3>
                <p>
                  Thank you for visiting the website of Tasty Pizza, Dartmouth’s
                  source for guaranteed great food at everyday low prices for 20
                  years. Come in, carry out or order delivery of our pizza,
                  donairs, seafood and subs!
                </p>
                <p>
                  We offer free parking for your convenience – you’ll love our
                  warm, friendly environment. Feel free to call in and carry out
                  your order, or dial for delivery. We even offer debit at your
                  door when you request it in advance. Just call the number on
                  this page!
                </p>
                <p>
                  For less timely questions or concerns, please use our
                  convenient e-form to email us. We’ll get back with you right
                  away.
                </p>

                <div className="contact-tp-list">
                  <p>
                    We proudly serve the Dartmouth and the surrounding areas:
                  </p>
                  <ul>
                    <li>Cole Harbour</li>
                    <li>Forest Hill</li>
                    <li>Lake Echo</li>
                    <li>Main Street</li>
                    <li>Westphal</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <StaticImage src="../images/img-1.jpg" />
              <img src="assets/img/img-1.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <ContactForm /> */}
      <StoreInformation />
      <div class="checkout-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="checkout-text pr-2">
                <h2>Methods of Payment*</h2>
                <div class="ck-bottom-list">
                  <p>Open 7 days a week</p>
                  <p>
                     Delivery on orders over $10 after 2pm in our local area with minimum $2.00 delivery charges.
                  </p>
                  <StaticImage src="../images/payment.png" />
                  <ul>
                    <li>
                      <span>*</span> Debit at your door (upon request){" "}
                    </li>
                    <li>
                      <span>*</span> HST not included{" "}
                    </li>
                    <li>
                      <span>*</span> Prices & store hours are subject to change without notice
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="checkout-thumb">
                <StaticImage src="../images/img-3.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
